/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useTheme } from '@mui/material/styles';
import p1 from 'assests/Photos/ClassAssembly/8A/3.jpg'
import p2 from 'assests/Photos/ClassAssembly/8A/2.jpg'
import p3 from 'assests/Photos/ClassAssembly/8A/1.jpg'


import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

// import Card from '@mui/material/Card';
// import CardContent from '@mui/material/CardContent';
// import Grid from '@mui/material/Grid';
// import TextField from '@mui/material/TextField';
// import Button from '@mui/material/Button';
// // import Divider from '@mui/material/Divider';
// import Avatar from '@mui/material/Avatar';
// import IconButton from '@mui/material/IconButton';
// import FacebookIcon from '@mui/icons-material/Facebook';
// import TwitterIcon from '@mui/icons-material/Twitter';
// import InstagramIcon from '@mui/icons-material/Instagram';

const Content = () => {
  const theme = useTheme();
 
  // const photos = [
  //   {
  //     src: 'https://assets.maccarianagency.com/backgrounds/img25.jpg',
  //     rows: 1,
  //     cols: 2,
  //   },
  //   {
  //     src: 'https://assets.maccarianagency.com/backgrounds/img22.jpg',
  //     rows: 1,
  //     cols: 1,
  //   },
  //   {
  //     src: 'https://assets.maccarianagency.com/backgrounds/img24.jpg',
  //     rows: 1,
  //     cols: 1,
  //   },
  //   {
  //     src: 'https://assets.maccarianagency.com/backgrounds/img21.jpg',
  //     rows: 1,
  //     cols: 2,
  //   },
  // ];

  return (
    <Box>
      <Box paddingX={{ xs: 0, sm: 4, md: 6 }}>
        <Typography variant='h4' align='center'>
        
        </Typography>
        <Typography  fontSize = "x-small">
        Class: 8A <br/>
        Date: 11 October 2022<br/>
        </Typography>
        <br />
        <Typography variant={'subtitle1'} align={'justify'}>
        Students of class 8A presented an inspiring assembly on the occasion of International Day of the Girl
Child (11.10.22) showcasing the prejudices and discrimination by the society towards a girl. The
students conveyed the message through a skit which emphasized on gender inequality and
discrimination of girls by the society.<br/><br/>
The boys of the class showed their empathy and solidarity through a song and the girls performed an
enchanting dance to show how powerful and strong they are. The assembly drove home the point of
gender equality to bring about a progressive and positive change in the society. The assembly was
well received and appreciated by both the teachers and students.
        </Typography>
        <br/><br/>
        <Typography
          variant={'h5'}
          color={'primary'}
          align={'center'}
          fontWeight={'normal'}
        >
         “WHEN YOU EDUCATE A GIRL CHILD, YOU EDUCATE A NATION”
        </Typography>
        <Box width={1} height={1} marginY={4}>
          <LazyLoadImage
            height={'100%'}
            width={'100%'}
            src={p1}
            alt="Remote working"
            effect="blur"
            style={{
              filter:
                theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
              objectFit: 'cover',
              borderRadius: 8,
              width: '100%',
              height: '100%',
              maxHeight: 400,
            }}
          />
        </Box>
   
        <LazyLoadImage
            height={'50%'}
            width={'50%'}
            src={p2}
            alt="Remote working"
            effect="blur"
            style={{
              filter:
                theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
              objectFit: 'cover',
              borderRadius: 8,
              width: '100%',
              height: '100%',
              maxHeight: 400,
            }}
          />
               <LazyLoadImage
            height={'50%'}
            width={'50%'}
            src={p3}
            alt="Remote working"
            effect="blur"
            style={{
              filter:
                theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
              objectFit: 'cover',
              borderRadius: 8,
              width: '100%',
              height: '100%',
              maxHeight: 400,
            }}
          /><br/>

        <Box marginY={4}>
        </Box>
      </Box>
    </Box>
  );
};

export default Content;
